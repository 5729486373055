.your-history {
  .your-history__content-top {
    margin-top: 29px;
  }

  .your-history__options {
    list-style: none;
    margin-top: 35px;

    li {
      border: 1px solid transparent;
      background: rgba(245, 195, 113, 0.2);
      border-radius: 4px;
      color: #3f414e;
      font-size: 16px;
      padding: 20px 15px;

      &:not(:first-of-type) {
        margin-top: 10px;
      }

      &.selected {
        border: 1px solid #fa6e5a;
        position: relative;

        &:after {
          background: url("./selected-icon.svg") no-repeat;
          background-size: cover;
          content: "";
          display: block;
          height: 20px;
          right: 10px;
          position: absolute;
          transform: translateY(-50%);
          top: 50%;
          width: 20px;
        }
      }
    }
  }

  .button {
    margin-top: 26px;
  }
}
