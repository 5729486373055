.header {
  padding: 22px 0;

  .container {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .header__button {
    align-items: center;
    border: 1px solid #ebeaec;
    border-radius: 50%;
    display: flex;
    left: 14px;
    justify-content: center;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    height: 48px;
    width: 48px;

    &.menu {
      border: 0;
      width: auto;
    }
  }
}
