.stories {
  .stories__content {
    align-items: center;
    display: flex;
  }

  .stories__list {
    li {
      border: 2px solid #12e04c;
      border-radius: 50%;
      display: flex;
      height: 49px;
      overflow: hidden;
      margin: 0 20px;
      width: 49px;

      img {
        display: block;
        width: 100%;
      }
    }
  }
}
