.onboarding-splash__wrapper {
  align-items: center;
  background-color: #fa6e5a;
  display: flex;
  justify-content: center;
  min-height: 100vh;

  h1 {
    color: white;
    font-family: "Roboto Slab", serif;
    font-size: 42px;
    text-align: center;
  }
}
